.first-level-position {
  left: 5%;
}

.second-level-position {
  left: 15%;
}

.fourth-level-position {
  left: 45%;
}
