/* COMFORTAA */

@font-face {
  font-family: 'Comfortaa';
  src: url('../../assets/fonts/Comfortaa-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../../assets/fonts/Comfortaa-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../../assets/fonts/Comfortaa-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../../assets/fonts/Comfortaa-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Comfortaa';
  src: url('../../assets/fonts/Comfortaa-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
